import { PageContainer } from "../../components";

const Multiswap = () => {
    return (
        <PageContainer>
            Multiswap page
        </PageContainer>
    )
}

export default Multiswap;