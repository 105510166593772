import city from "../../../assets/images/city.png"
const Footer = () => {
    return (

        <div className="Footer">
            <img src={city}></img>
        </div>
    )
}

export default Footer;